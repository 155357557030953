import { useEffect, useState } from 'react'
import query from 'url-query'

const useQuerystring = () => {
  const [result, setResult] = useState({})

  useEffect(() => {
    const loc = document.location

    if (loc.search) {
      setResult(query(loc.search))
    }
  }, [])

  return result
}

export default useQuerystring
