import 'focus-visible'
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-slider/assets/index.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import '../styles/index.css'

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { Elements as StripeProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import IESupport from 'components/IESupport'
import Intercom from 'components/Intercom'
import IntlProvider from 'components/IntlProvider'
import Layout from 'components/Layout'
import LogRocketProvider from 'components/LogRocketProvider'
import { initApolloClient } from 'lib/apollo'
import env from 'lib/env'
import { validateLocale } from 'lib/getLocale'
import getMessages from 'lib/getMessages'
import { GroupProvider } from 'lib/GroupContext'
import { QueryParamProvider } from 'lib/QueryParamProvider'
import { UserProvider } from 'lib/UserContext'
import App from 'next/app'
import { resetIdCounter } from 'react-tabs'
import { ToastContainer } from 'react-toastify'
import { setLocale } from 'yup'

// Source is here: https://github.com/jquense/yup/blob/master/src/locale.ts
setLocale({
  mixed: {
    default: { id: 'form.invalidField' },
    required: { id: 'form.requiredField' },
  },
  number: {
    max: ({ max }) => ({ id: 'form.validation.number.max', values: { max } }),
    min: ({ min }) => ({ id: 'form.validation.number.min', values: { min } }),
    required: { id: 'form.requiredField' },
  },
  string: {
    email: { id: 'form.validation.string.email' },
    url: { id: 'form.validation.string.url' },
    max: ({ max }) => ({ id: 'form.validation.string.max', values: { max } }),
    min: ({ min }) => ({ id: 'form.validation.string.min', values: { min } }),
    required: { id: 'form.requiredField' },
  },
})

/*
if (typeof window === 'undefined') {
  // dom parser for FormatedHTMLMessages
  global.DOMParser = new (require('jsdom').JSDOM)().window.DOMParser
}
*/

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
})

export default class CustomApp extends App {
  constructor(props) {
    super(props)

    this.stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PK, {
      locale: validateLocale(this.props.router.query.locale),
    })
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps, router } = this.props

    const client = initApolloClient(pageProps.apolloState)

    const Wrapper = Component.Layout || Layout

    const locale = validateLocale(router.query.locale)

    const messages = getMessages(locale, pageProps.groupId)

    resetIdCounter()

    return (
      <IntlProvider locale={locale} messages={messages}>
        <IESupport>
          <QueryParamProvider>
            <ApolloProvider client={client}>
              <StripeProvider stripe={this.stripePromise}>
                <GroupProvider
                  locale={locale}
                  hasServerSideProps={pageProps.hasServerSideProps}
                  groupId={pageProps.groupId}
                >
                  <UserProvider>
                    {typeof window !== 'undefined' && env.intercomAppId && (
                      <Intercom appId={env.intercomAppId} />
                    )}
                    <LogRocketProvider appID={env.logRocketID} />
                    <Wrapper {...pageProps}>
                      <Component {...pageProps} key={router.route} />
                    </Wrapper>
                  </UserProvider>
                </GroupProvider>
              </StripeProvider>
            </ApolloProvider>
          </QueryParamProvider>
        </IESupport>
        <ToastContainer
          autoClose={2000}
          position="bottom-left"
          hideProgressBar
        />
      </IntlProvider>
    )
  }
}
