import BlankLayout from 'components/BlankLayout'
import { FC, ReactNode } from 'react'
import { AlertCircle, Icon } from 'react-feather'
// import { useIntl } from 'react-intl'

export type StatusPageProps = {
  icon?: Icon
  subTitle?: string
  title?: string
  children: ReactNode
}

const StatusPage: FC<StatusPageProps> = ({
  icon,
  subTitle,
  title,
  children,
}) => {
  const Icon = icon || AlertCircle

  return (
    <BlankLayout>
      <Icon className="mx-auto mb-4" size={48} />
      <h1 className="text-2xl mb-4 font-bold">{title}</h1>
      {subTitle && <h2 className="text-2xl mb-2">{subTitle}</h2>}
      <div>{children}</div>
    </BlankLayout>
  )
}

export default StatusPage
