import StatusPage from './StatusPage'

const BrowserWarning = () => {
  return (
    <StatusPage title="IE is not supported">
      Please use a different browser
    </StatusPage>
  )
}

export default BrowserWarning
