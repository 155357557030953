import useIntercom from '@mentorly/react-intercom-hook'
import { useCurrentUser } from 'lib/UserContext'
import { capitalize } from 'lodash'

const prepareIntercomProps = (currentUser, appId) => {
  const intercomProps = {
    app_id: appId,
    custom_launcher_selector: '#custom_intercom_button',
  }

  if (!currentUser) {
    return intercomProps
  }

  intercomProps.user_id = currentUser.id
  intercomProps.user_hash = currentUser.intercomHash
  intercomProps.email = currentUser.contactEmail
  intercomProps.name = currentUser.name
  intercomProps.Role = capitalize(currentUser.userRole)

  if (currentUser.avatar.imageUrl) {
    intercomProps.avatar = {
      type: 'avatar',
      image_url: currentUser.avatar.imageUrl,
    }
  }

  if (currentUser.group) {
    intercomProps.Marketplace = false
    intercomProps.company = {
      id: currentUser.group.id,
      name: currentUser.group.name,
      Slug: currentUser.group.slug,
    }
  } else {
    intercomProps.Marketplace = true
  }

  return intercomProps
}

const Intercom = ({ appId }) => {
  const { currentUser } = useCurrentUser()
  useIntercom(prepareIntercomProps(currentUser, appId))

  return null
}

export default Intercom
