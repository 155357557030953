import { useRouter } from 'next/router'
import React, { memo, useMemo } from 'react'
import { QueryParamProvider as ContextProvider } from 'use-query-params'

export const QueryParamProviderComponent = (props) => {
  const { children, ...rest } = props
  const router = useRouter()
  const match = router.asPath.match(/[^?]+/)
  const pathname = match ? match[0] : router.asPath

  const location = useMemo(
    () =>
      process.browser
        ? window.location
        : {
            search: router.asPath.replace(/[^?]+/u, ''),
          },
    [router.asPath]
  )

  const history = useMemo(
    () => ({
      push: ({ search }) =>
        router.push(router.pathname, { search, pathname }, { shallow: true }),
      replace: ({ search }) =>
        router.replace(
          router.pathname,
          { search, pathname },
          { shallow: true }
        ),
    }),
    [pathname, router]
  )

  return (
    <ContextProvider {...rest} history={history} location={location}>
      {children}
    </ContextProvider>
  )
}

export const QueryParamProvider = memo(QueryParamProviderComponent)
