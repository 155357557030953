import Footer from 'components/Footer/Footer'
import Header from 'components/Header/Header'
import { motion } from 'lib/framer-motion'
import useQuerystring from 'lib/useQuerystring'
import useScrollTop from 'lib/useScrollTop'
import CookieConsent from 'react-cookie-consent'
import { useIntl } from 'react-intl'

const CookieMessage = ({ locale }) => {
  if (locale === 'fr') {
    return (
      <>
        Nous utilisons des cookies pour analyser le trafic du site et nous
        partageons également des informations sur votre utilisation de notre
        site avec des partenaires d&apo;analyse. En cliquant sur accepter, vous
        autorisez la réception de cookies{' '}
        <a href="https://www.intercom.com/terms-and-policies#cookie-policy">
          Intercom
        </a>
        , <a href="https://www.fullstory.com/legal/privacy/">Fullstory</a>, et{' '}
        <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
          Google Analytics
        </a>{' '}
        qui analysent et mesurent votre engagement sur le site Web Mentorly.
        Vous pouvez{' '}
        <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=fr">
          modifier vos paramètres de cookies
        </a>{' '}
        à tout moment.
      </>
    )
  }

  return (
    <>
      We use cookies to analyze site traffic. We also share information about
      your use of our site with analytics partners. By clicking accept,
      you&apos;re allowing to receive{' '}
      <a href="https://www.intercom.com/terms-and-policies#cookie-policy">
        Intercom
      </a>
      , <a href="https://www.fullstory.com/legal/privacy/">Fullstory</a>, and{' '}
      <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
        Google Analytics
      </a>{' '}
      cookies that analyze and measure your engagement on the Mentorly website.
      You can{' '}
      <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">
        {' '}
        change your cookie settings
      </a>{' '}
      at anytime.
    </>
  )
}

const ScrollToTop = () => {
  const [showScroll, scrollTop] = useScrollTop()

  if (!showScroll) {
    return null
  }

  return (
    <button
      className="fixed right-0 bottom-0 m-6 font-black bg-white rounded-full w-6 h-6 shadow z-50 focus:outline-none"
      onClick={scrollTop}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        <path d="M18 15l-6-6-6 6" />
      </svg>
    </button>
  )
}

const hasWindow = () => typeof window !== 'undefined'

export const Layout = ({ children }) => {
  const { formatMessage, locale } = useIntl()
  const qs = useQuerystring()

  const data = [
    { id: 'menu.home', path: `/`, className: 'hidden xl:block' },
    { id: 'menu.pricing', path: `/pricing` },
    { id: 'menu.caseStudies', path: `/case-studies` },
    { id: 'menu.faq', path: `/faq` },
    {
      id: 'menu.marketplace',
      path: 'https://marketplace.mentorly.co/',
      legacy: true,
    },
    {
      id: 'menu.blog',
      path: 'http://mentorlyblog.co/category/enterprise/',
      legacy: true,
    },
  ]

  // handle return from form submission
  if (qs.firstname) {
    // Router.push(`/${locale}/thank-you`)
  }

  if (hasWindow() && qs.m) {
    window.location.replace(`/mentors?m=${qs.m}`)
  }

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
      className="animator"
    >
      <div className="w-full">
        <Header data={data} />
        <main className="w-full h-full z-0">{children}</main>
        <Footer />
        <ScrollToTop />
      </div>
      <CookieConsent
        buttonText={formatMessage({ id: 'button.accept' })}
        buttonWrapperClasses="self-end"
      >
        <CookieMessage locale={locale} />
      </CookieConsent>
    </motion.div>
  )
}

export default Layout
