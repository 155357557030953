import GroupLayout from 'components/pages/Groups/Layout'
import HomeLayout from 'components/pages/Home/Layout'

export const Layout = ({ children, groupId }) => {
  if (groupId) {
    return <GroupLayout groupId={groupId}>{children}</GroupLayout>
  }

  return <HomeLayout>{children}</HomeLayout>
}

export default Layout
